/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'
import { graphql, Link } from 'gatsby'
import styled from 'styled-components'

// Elements
import { ParseContent, Image, SEO } from 'components/shared'
import { Button, CustomerCase } from 'components/elements'

// Components
import Layout from 'components/layout/Layout'

// Animation
import Lottie from 'react-lottie'

// Images
import BackImg from 'img/back-logo.png'
import ArrowLeftImg from 'img/arrow-left.svg'
import ArrowRightImg from 'img/arrow-right.svg'
import ShineImg from 'img/shine.png'

const Content = styled(ParseContent)`
  h1 {
    br {
      display: none;
    }
  }

  h4,
  h5 {
    letter-spacing: 2px;
  }

  h4 {
    color: rgba(197, 197, 197, 0.4);
    font-size: ${(props) => props.theme.font.size.m};
  }

  & table {
    width: 100%;

    td {
      padding-right: 30px;
      vertical-align: top;
    }

    p {
      font-size: ${(props) => props.theme.font.size.sm};
    }
  }
`

const StyledImage = styled(Image)`
  pointer-events: none;
`

const StyledLottie = styled(Lottie)``

const ContentWrapper = styled.div`
  & table {
    table-layout: fixed;

    & h5 {
      font-size: ${(props) => props.theme.font.size['18']};
    }

    @media (max-width: 575px) {
      & td {
        display: block;
        width: 100% !important;
        margin-bottom: 1rem;

        &:last-child {
          margin-bottom: none;
        }
      }
    }
  }
`

const LottieWrapper = styled.div`
  & svg {
    max-height: 275px;
  }
`

const PageTemplate = ({
  data: {
    page: { path, title, yoast_meta: yoast, acf },
    menu: {
      acf: { menu },
    },
  },
}) => {
  const navData = menu
    .filter((d) => d.link.title === 'Upgrades')[0]
    .submenu.map((d) => d.link)
  const currentNavIndex = navData.findIndex((d) => d.title === title)

  return (
    <Layout>
      <SEO yoast={yoast} path={path} />
      <Section1>
        <ShineImage>
          <Image src={ShineImg} style={{ width: '100%' }} />
        </ShineImage>

        <div className="d-flex position-relative">
          <NavButtonWrapper className="d-flex h-100 flex-column justify-content-center">
            {currentNavIndex !== 0 && navData[currentNavIndex - 1] && (
              <NavButton
                direction="left"
                to={navData[currentNavIndex - 1].url}
              />
            )}
          </NavButtonWrapper>
          <div className="container">
            <div className="d-flex justify-content-between flex-wrap">
              <ContentWrapper className="col-lg-7">
                <div className="px-lg-0 px-3">
                  <Content content={acf.banner.description} />
                </div>
              </ContentWrapper>
              <LottieWrapper className="col-lg-4 d-flex pt-5 justify-content-end mr-lg-0">
                <StyledLottie
                  options={{
                    animationData: JSON.parse(acf.banner.animation),
                    autoplay: true,
                    loop: false,
                    rendererSettings: {
                      preserveAspectRatio: 'xMidYMid slice',
                    },
                  }}
                  width="100%"
                />
              </LottieWrapper>
            </div>
          </div>
          <NavButtonWrapper
            direction="right"
            className="d-flex flex-column h-100 justify-content-center"
          >
            {currentNavIndex !== navData.length - 1 &&
              navData[currentNavIndex + 1] && (
                <NavButton
                  direction="right"
                  to={navData[currentNavIndex + 1].url}
                />
              )}
          </NavButtonWrapper>
        </div>
        <div className="button-container d-flex w-100 justify-content-center">
          <Button to="/werkwijze">Start</Button>
        </div>
        <UBXImage>
          <StyledImage src={BackImg} style={{ width: '100%' }} />
        </UBXImage>
      </Section1>
      <Section2>
        <div className="container">
          <CustomerJourney fields={acf.box} />
        </div>
      </Section2>
      {acf.klantcase.description && (
        <>
          <div className="container">
            <Section3>
              <WhatOthers className="position-relative">
                <h3>
                  Wat anderen
                  <br />
                  zeggen
                </h3>
              </WhatOthers>
              <div className="content px-3 px-lg-0 mt-5 mt-lg-0">
                <CustomerCase fields={acf.klantcase} />
              </div>
            </Section3>
            <div className="py-sm-5 py-3" />
          </div>
        </>
      )}

      <div className="py-lg-5" />
    </Layout>
  )
}

const WhatOthers = styled.div`
  z-index: 1;
`

const Section1 = styled.div`
  h1 {
    color: ${(props) => props.theme.color.text.light};
    font-family: ${(props) => props.theme.font.family.main};
    white-space: pre-line;
    width: min-content;

    @media (min-width: 992px) {
      font-size: ${(props) => props.theme.font.size.xxl};
    }

    @media (max-width: 991px) {
      font-size: ${(props) => props.theme.font.size.xl};
    }
  }

  h5 {
    color: ${(props) => props.theme.color.face.secondary};
    font-size: ${(props) => props.theme.font.size.xm};
    font-family: ${(props) => props.theme.font.family.main};
  }

  p {
    color: ${(props) => props.theme.color.face.light2};
    font-size: ${(props) => props.theme.font.size.sm};
    line-height: ${(props) => props.theme.font.size.l};
    font-family: ${(props) => props.theme.font.family.secondary};
  }
  margin-top: 150px;

  .button-container {
    margin-top: 105px;
    position: absolute;
  }

  margin-bottom: 70px;

  @media screen and (max-width: 768px) {
    margin-top: 50px;
  }
`

const Section2 = styled.div`
  padding-bottom: 250px;

  @media screen and (max-width: 768px) {
    padding-bottom: 120px;
  }
`

const Section3 = styled.div`
  padding-bottom: 100px;
  h3 {
    opacity: 0.3;
    font-size: ${(props) => props.theme.font.size.xxxl};
    font-family: ${(props) => props.theme.font.family.main};
  }
  .content {
    position: relative;
    margin-top: -50px;
  }
  @media screen and (max-width: 1024px) {
    padding-bottom: 100px;

    h3 {
      opacity: 0.3;
      font-size: ${(props) => props.theme.font.size.l};
      font-family: ${(props) => props.theme.font.family.main};
    }
  }
`

const BoxContent = styled(ParseContent)`
  td {
    vertical-align: top;
    
    @media (min-width: 992px) {
      padding: 10px;
    }

    @media screen and (max-width: 991px) {
      width: 100% !important;
    }
  }

  tr {
    @media screen and (max-width: 991px) {
      display: flex;
      flex-wrap: wrap;
    }
  }

  p,
  td {
    line-height: 27px;
  }
`

const StyledCustomerJourney = styled.div`
  max-width: 900px;
  margin: 0 auto;

  button {
    font-size: ${(props) => props.theme.font.size.m};
    line-height: ${(props) => props.theme.font.size.m};
    color: ${(props) => props.theme.color.text.main};
    border: 2px solid ${(props) => props.theme.color.text.main};
    height: 50px;
    border-radius: 25px;
    padding-left: 40px;
    padding-right: 40px;
    transition: all 300ms ease;
    &:hover {
      background: ${(props) => props.theme.color.text.main};
      color: ${(props) => props.theme.color.text.light};
    }
  }
`

const CustomerJourney = ({ fields }) => (
  <StyledCustomerJourney>
    <CustomerJourneyContainer>
      <BoxContent className="p-4 p-lg-0" content={fields.description} />
    </CustomerJourneyContainer>
    <CustomerJourneyFooter className="d-flex flex-wrap ml-auto justify-content-between mt-n3">
      <h4 className="my-auto">The time is now!</h4>
      <a className="my-auto" href="javascript:void(Tawk_API.toggle())">
        <button type="button" className="mt-4">UPGRADEN</button>
      </a>
    </CustomerJourneyFooter>
  </StyledCustomerJourney>
)

const CustomerJourneyContainer = styled.div`
  color: ${(props) => props.theme.color.text.main};
  background: ${(props) => props.theme.color.text.light};
  font-size: ${(props) => props.theme.font.size.sm};
  font-family: ${(props) => props.theme.font.family.secondary};

  h2,
  h3 {
    font-weight: ${(props) => props.theme.font.weight.l};
    font-family: ${(props) => props.theme.font.family.main};

    @media (min-width: 992px) {
      font-size: ${(props) => props.theme.font.size.xl};
    }

    @media (max-width: 991px) {
      font-size: ${(props) => props.theme.font.size.l};
      text-align: left !important;
    }
  }

  .customer-content {
    line-height: 28px;
    column-count: 2;

    @media screen and (max-width: 768px) {
      column-count: 1;
    }
  }

  @media (min-width: 992px) {
    padding: 60px 80px 100px 80px;
  }
`

const CustomerJourneyFooter = styled.div`
  width: 700px;
  height: 100px;
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
  padding-left: 50px;
  padding-right: 150px;
  padding-top: 10px;
  padding-bottom: 10px;
  background: ${(props) => props.theme.color.face.secondary};
  position: relative;
  margin-top: -50px;

  h4 {
    font-size: ${(props) => props.theme.font.size.l};
    line-height: ${(props) => props.theme.font.size.l};
    color: ${(props) => props.theme.color.text.light};
  }

  button {
    font-size: ${(props) => props.theme.font.size.m};
    line-height: ${(props) => props.theme.font.size.m};
    color: ${(props) => props.theme.color.text.main};
    border: 2px solid ${(props) => props.theme.color.text.main};
    height: 50px;
    border-radius: 25px;
    padding-left: 40px;
    padding-right: 40px;
    transition: all 300ms ease;
    &:hover {
      background: ${(props) => props.theme.color.text.main};
      color: ${(props) => props.theme.color.text.light};
    }
  }

  @media screen and (max-width: 1024px) {
    width: 500px;
  }

  @media screen and (max-width: 768px) {
    width: 300px;
    height: 120px;
    padding-right: 20px;
    border-top-left-radius: 60px;
    border-bottom-left-radius: 60px;
  }
`

const NavButtonWrapper = styled.div`
  position: absolute;
  right: ${(props) => (props.direction === 'right' ? 0 : 'auto')};
`

const NavButton = ({ direction, to }) => (
  <NavButtonContainer
    direction={direction}
    to={to}
    className="d-flex flex-column justify-content-center"
  >
    {direction === 'left' ? (
      <NavButtonContainerLeft src={ArrowLeftImg} alt="arrow" />
    ) : (
      <NavButtonContainerRight src={ArrowRightImg} alt="arrow" />
    )}
  </NavButtonContainer>
)

const NavButtonContainerLeft = styled.img`
  position: relative;
  left: 10px;
`

const NavButtonContainerRight = styled.img`
  position: relative;
  /* right: 10px; */
`

const NavButtonContainer = styled(Link)`
  background: rgba(255, 255, 255, 0.45);
  width: 54px;
  height: 108px;
  border-top-left-radius: ${(props) =>
    props.direction === 'left' ? '0' : '54px'};
  border-top-right-radius: ${(props) =>
    props.direction === 'left' ? '54px' : '0'};
  border-bottom-left-radius: ${(props) =>
    props.direction === 'left' ? '0' : '54px'};
  border-bottom-right-radius: ${(props) =>
    props.direction === 'left' ? '54px' : '0'};

  img {
    margin-left: ${(props) => (props.direction === 'left' ? '-12px' : '12px')};
    
    @media (min-width: 992px) {
      width: 40px;
      height: 40px;
    }

    @media (max-width: 991px) {
      width: 20px;
      height: 20px;
    }
  }

  cursor: pointer;
  transition: all 300ms ease;

  &:hover {
    background: rgba(255, 255, 255, 0.25);
  }

  @media screen and (max-width: 768px) {
    width: 32px;
    height: 64px;
    border-top-left-radius: ${(props) =>
      props.direction === 'left' ? '0' : '32px'};
    border-top-right-radius: ${(props) =>
      props.direction === 'left' ? '32px' : '0'};
    border-bottom-left-radius: ${(props) =>
      props.direction === 'left' ? '0' : '32px'};
    border-bottom-right-radius: ${(props) =>
      props.direction === 'left' ? '32px' : '0'};
    img {
      margin-left: ${(props) => (props.direction === 'left' ? '-6px' : '6px')};
    }
  }
`

const UBXImage = styled.div`
  width: 80%;
  pointer-events: none;
  position: relative;
  background-size: contain !important;
  background-position-x: 0 !important;

  @media (min-width: 992px) {
    margin-top: -80px;
  }

  @media screen and (max-width: 768px) {
    margin-top: 80px;
    width: 100%;
  }

  & .gatsby-image-wrapper {
    max-height: 250px;
    overflow: initial !important;

    & img {
      max-height: 350px;
      object-fit: contain;
    }
  }
`

const ShineImage = styled.div`
  position: absolute;
  right: 0;
  top: 40px;
`

export default PageTemplate

export const pageQuery = graphql`
  query($wordpress_id: Int) {
    page: wordpressPage(wordpress_id: { eq: $wordpress_id }) {
      title
      path

      acf {
        banner {
          description
          animation
        }

        box {
          description
        }

        klantcase {
          quote
          description
          image {
            localFile {
              childImageSharp {
                fluid(quality: 100, maxWidth: 1920) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
        }
      }
      yoast_meta {
        name
        content
        property
      }
    }
    menu: wordpressWpComponenten(wordpress_id: { eq: 97 }) {
      acf {
        menu {
          link {
            title
            url
            target
          }
          submenu {
            link {
              title
              url
            }
          }
        }
      }
    }
  }
`
